import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFound = () => (
  <Layout classNames="flex flex-row content-center">
    <SEO title="Not Found" />
    <div className="m-auto">
      <h1 className="text-xl font-bold uppercase">Error 404: Page Not Found</h1>
      <p className="mb-5 text-lg font-light lowercase">
        Psst, You know there is only one webpage right?
      </p>
      <a
        className="text-blue-600 hover:text-blue-600 lowercase underline cursor-pointer"
        href="/"
        aria-label="link to main page"
      >
        Go back to civilization
      </a>
    </div>
  </Layout>
)

export default NotFound
